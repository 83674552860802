<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  mounted() {
    // this.setIrame()
  },
  created() {
    // 电脑端显示iframe
    this.setIrame()
  },
  methods: {
    isMobile() {
      return (
        typeof window.orientation !== "undefined" || // 判断是否存在window.orientation属性，此属性在移动设备上
          navigator.userAgent.indexOf('IEMobile') !== -1 || //判断是否为Windows phone
          navigator.userAgent.indexOf('iPhone') !== -1 || // 判断是否为iPhone
          navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1 || // 判断是否为安卓手机
          navigator.userAgent.indexOf('BlackBerry') !== -1 || //判断是否为BlackBerry
          navigator.userAgent.indexOf( 'Opera Mini') !== -1 // 判断是否为Opera Mini浏览器
      )
    },
    setIrame() {
      if (this.isMobile()) return
      if (window.innerWidth < 960) return
      // 判断是否在iframe内
      if (window.frames.length !== parent.frames.length) return
      let ifrTag = document.getElementsByTagName('iframe')[0]
      if (ifrTag) {
        ifrTag.remove()
      } else {
        let ifrTag = document.createElement('iframe')
        document.body.innerHTML = ''
        ifrTag.setAttribute('src', window.location.href)
        let styleObj = {
          width: '375px',
          height: '98vh',
          position: 'absolute',
          top: '1vh',
          left: '50%',
          transform: 'translateX(-50%)'
        }
        Object.entries(styleObj).forEach(([key, val]) => {
          ifrTag.style[key] = val
        })
        document.body.append(ifrTag)
      }
    }
  }
}
</script>

<style>
</style>
